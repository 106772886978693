var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { "margin-bottom": "20px" } },
    [
      _c("div", { staticClass: "titleBox", on: { click: _vm.isClick } }, [
        _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
        _c("div", { staticClass: "status", style: _vm.styleTxt }, [
          _vm._v(_vm._s(_vm.status)),
        ]),
        _c("div", { staticClass: "icon", class: _vm.onClick ? "onClick" : "" }),
      ]),
      _vm.onClick ? _vm._t("default") : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }