<template>
  <div style="margin-bottom: 20px;">
    <div class="titleBox" @click="isClick">
      <div class="title">{{title}}</div>
      <div class="status" :style="styleTxt">{{status}}</div>
      <div class="icon" :class="onClick ? 'onClick' : ''"></div>
    </div>
    <slot v-if="onClick"></slot>
  </div>
</template>
<script>
export default {
  props: ['title', "status"],
  data () {
    return {
      onClick: true
    }
  },
  methods: {
    isClick() {
      this.onClick = !this.onClick
    }
  },
  computed: {
    styleTxt: function () {
      let style = ''
      if (this.status === '待处理') {
        style = 'background: rgba(250, 205, 145, 1)'
      } else if (this.status === '跟进中') {
        style = 'background: rgba(128, 255, 255, 1)'
      } else {
        style = 'background: rgba(130, 249, 142, 1)'
      }
      return style
    }
  }
}
</script>
<style lang="stylus" scoped>
  .titleBox {
    cursor: pointer;
    overflow hidden;
    border-left 3px solid #3F9EFF;
    margin-bottom 20px;
    background: #EFF2F7
    .icon {
      float right 
      background url("../../../../assets/img/up.png") no-repeat 100% 100% / 100% 100%
      height 34px;
      width 34px;
    }
    .onClick {
      background url("../../../../assets/img/down.png") no-repeat 100% 100% / 100% 100%
    }
    .status {
      position absolute
      right 100px;
      margin-top 10px;
    }
    .title {
      margin-left 10px;
      float left
      color #333333
      font-size 14px;
      line-height 34px;
    }
  }
</style>
